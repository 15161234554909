import React from "react"
import KeyboardIcon from "../../static/images/keyboard.png"

const ToggleKeyboard = ({ isActive, onClick }) => {
  return (
    <a
      className="btn btn-circle mr-3 d-flex justify-content-center align-items-center"
      data-toggle="collapse"
      onClick={e => {
        e.preventDefault()
        onClick && onClick()
      }}
      aria-expanded="false"
      aria-controls="collapseExample"
      style={{ backgroundColor: isActive ? "#68a1fc" : "#f7f9fc" }}
    >
      <img style={{ height: "30px" }} src={KeyboardIcon} height="20" />
    </a>
  )
}
export default ToggleKeyboard
